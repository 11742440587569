import { useQuery } from '@apollo/react-hooks';

import { useIsLicensed } from '@confluence/session-data';

import { GetLicensedProductsQuery } from './GetLicensedProducts.graphql';
import type {
	GetLicensedProducts as GetLicensedProductsType,
	GetLicensedProducts_confluence_tenantContext_licensedProducts,
} from './__types__/GetLicensedProductsQuery';

/**
 * Determine user type (land/xflow) by licensedProducts
 * This should only be used within the Onboarding experience and Account Setup Modal flow for admins
 *
 * @param {boolean} skip - if the GetLicensedProductsQuery should be skipped
 * @returns {boolean} isLandUser - determines if the user is a land user by licensedProducts
 * @returns {boolean} isXflowUser - determines if the user is a cross flow user by licensedProducts
 * @returns {boolean} loading - if GetLicensedProductsQuery is still loading
 * @returns {object} error - An error object representing any errors encountered during the query execution.
 */
export const useUserByLicensedProducts = ({ skip = false }: { skip?: boolean }) => {
	const isLicensed = useIsLicensed();

	const skipConditions = skip || !isLicensed;

	const {
		data: licensedProductsData,
		loading: licensedProductsLoading,
		error: licensedProductsError,
	} = useQuery<GetLicensedProductsType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		GetLicensedProductsQuery,
		{
			errorPolicy: 'all',
			skip: skipConditions,
		},
	);

	if (licensedProductsLoading || licensedProductsError || skipConditions) {
		return {
			isLandUser: false,
			isXflowUser: false,
			loading: licensedProductsLoading,
			error: licensedProductsError,
		};
	}

	const licensedProducts = licensedProductsData?.confluence_tenantContext?.licensedProducts;
	const userByLicensedProducts = checkUserByLicensedProducts(licensedProducts);

	return {
		isLandUser: skipConditions ? false : userByLicensedProducts.isLandUser,
		isXflowUser: skipConditions ? false : userByLicensedProducts.isXflowUser,
		loading: licensedProductsLoading,
		error: licensedProductsError,
	};
};

/**
 *
 * @param licensedProducts
 * @returns
 * isXflowUser - if user has any active jira product
 * isLandUser - if user has only 1 active product and it's confluence
 *
 */
function checkUserByLicensedProducts(
	licensedProducts: GetLicensedProducts_confluence_tenantContext_licensedProducts[] | undefined,
) {
	if (!licensedProducts) {
		return { isXflowUser: false, isLandUser: false };
	}

	const activeLicensedProducts = licensedProducts.filter(
		(product) => product.licenseStatus === 'ACTIVE' && product.productKey !== 'rovo',
	);

	return {
		isXflowUser: activeLicensedProducts.some((product) =>
			product.productKey.toLowerCase().includes('jira'),
		),
		isLandUser:
			activeLicensedProducts.length === 1 && activeLicensedProducts[0].productKey === 'confluence',
	};
}
